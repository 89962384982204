<template>
  <div>
    <div class="pc">
      <div v-if="product.enable_option">
        <div class="lp-divider-gray1 padding-top-24"></div>
        <!-- 필수옵션 -->
        <div v-for="(selector, idx) in optionArr1" :key="`option1-${idx}`">
          <div v-if="!selector.disabled">
            <div class="padding-top-32 h8">{{ selector.name }}</div>
            <div :class="{'plan-select-wrapper': idx===1}">
              <!-- 옵션 정보 -->
              <template v-for="(option, o_idx) in selector.options">
                <div class="option-st unselect" :key="`op1-${o_idx}`"
                     :class="{'disabled': selector.disabled, 'selected': option.selected}"
                     @click="selectOption1(option, selector)"
                     v-if="!option.disabled">
                  <div class="body2-medium sub" v-html="optionName(option.name)"></div>
                  <div v-if="option.price>0 && idx>0">
                    <span class="body4-bold main">{{ option.price | currencyNum }}</span>
                    <span class="body4 sub">원</span>
                    <span class="body4 sub3" v-if="option.is_subscription">/월</span>
                  </div>
                  <div class="body5 sub3 margin-top-4">{{ option.desc }}</div>
                </div>
              </template>
            </div>
          </div>
        </div>

        <!-- 추가옵션 -->
        <div v-if="visibleOption2">
          <div v-for="(selector, idx) in optionArr2" :key="`option2-${idx}`">
          <div class="padding-top-32 h8">{{ selector.name }}</div>
          <!-- 옵션 정보 -->
          <div v-for="(option, o_idx) in selector.options" :key="`op2-${o_idx}`">
            <div class="option-st unselect"
                 :class="{'selected': option.selected}"
                 @click="selectOption2(option)">
              <div class="flex-between">
                <div class="body2-medium sub" v-html="optionName(option.name)"></div>
                <div class="margin-top-4" v-if="option.price>0">
                  <span class="body4-bold main">+{{ option.price | currencyNum }}</span>
                  <span class="body4 sub">원</span>
                  <span class="body4 sub3" v-if="option.is_subscription">/월</span>
                </div>
              </div>
              <div class="body5 sub3 margin-top-4">{{ option.desc }}</div>
            </div>
          </div>
        </div>
        </div>

        <!-- 선택된 옵션 정보 -->
      <!--  <div class="margin-top-16" v-if="order_product && product.enable_option && order_product.order_product_option.length > 0">
          <div v-for="(op, idx) in order_product.order_product_option" :key="`op-${idx}`">
            <div class="flex">
              <div v-if="op.option_type===0" style="min-width:66px;">[필수옵션]</div>
              <div v-else-if="op.option_type===1" style="min-width:66px;">[추가옵션]</div>
              <div style="margin-left:4px">{{ selectedOptionName(selectorStocks, op.option_id) }}</div>
            </div>
          </div>
        </div>-->

        <div class="lp-divider-gray1 padding-top-24"></div>
        <div class="flex-between padding-top-24">
          <div class="subtitle5">
            <span class="sub">상품금액</span>
          </div>
          <div>
            <span class="body2-bold main">
              {{ order_product.required_option_price | currencyNum }}
            </span>
            <span class="body4 sub">원</span>
          </div>
        </div>
        <div class="flex-between padding-top-12" v-if="order_product.add_option_price">
          <div class="subtitle5">
            <span class="sub">추가옵션</span>
          </div>
          <div>
            <span class="body2-bold main">
              {{ order_product.add_option_price | currencyNum }}
            </span>
            <span class="body4 sub">원</span>
          </div>
        </div>
        <div class="flex-between margin-top-12" v-if="!isRequiredOptionDesign">
          <div class="subtitle5">
            <span class="sub">{{ product.service_use_price.name }}</span>
            <span class="primary">(월결제)</span>
            <tool-tip style="margin-left:4px"
                      icon="u_question-circle"
                      text="사업 운영에 필요한 관리자 사이트 등이 포함된 운영 솔루션이 제공됩니다."/>
          </div>
          <div>
            <span class="body2-bold main">+{{ product.service_use_price.price | currencyNum }}</span>
            <span class="body4 sub">원</span>
            <span class="body4 sub3">/월</span>
          </div>
        </div>
        <div class="lp-divider-gray1 padding-top-24"></div>

        <div class="flex-between padding-top-24">
          <div class="h7">총 결제금액</div>
          <div class="h4">{{ order_product.total_price | currencyNum }}원</div>
        </div>
        <div v-if="isRequiredOptionDesignSkinAndModify">
          <div class="flex-align margin-top-8">
            <img class="svg-primary" src="/static/icon/fi_alert-circle.svg" style="width:20px;height:20px;margin-right:6px"/>
<!--            <i class="material-icons-outlined primary margin-right-4">info</i>-->
            <div class="body4 sub3">위 금액에는 견적서를 통해 발송될 수정 개발 서비스에 대한 요금이 포함되지 않았습니다.</div>
          </div>
          <div class="flex-align margin-top-8">
            <img class="svg-primary" src="/static/icon/fi_alert-circle.svg" style="width:20px;height:20px;margin-right:6px"/>
<!--            <i class="material-icons-outlined primary margin-right-4">info</i>-->
            <div class="body4 sub3">추가 수정 개발 요청 시, 충전금에서 서비스 이용 금액을 차감합니다.</div>
          </div>
        </div>
        <div class="padding-top-40">
          <button class="button is-primary body2-bold" style="width:100%;height:52px" @click="saveData">결제하기</button>
        </div>
      </div>
      <div v-else-if="product.category2===42 || product.category2===45">
        <div class="lp-divider-gray1 padding-top-24"></div>
        <div class="flex-between padding-top-24" v-if="product.price.price_type===0">
          <div class="h7">총 결제금액</div>
          <div class="h4">{{ discountedPrice | currency }}</div>
        </div>
        <div class="flex-end padding-top-24" v-else>
          <div class="h7">관리자에게 문의</div>
        </div>
        <div class="padding-top-40">
          <button class="button is-primary body2-bold" style="width:100%;height:52px" @click="clickInquiry">1:1 문의 하기</button>
        </div>
      </div>
      <!-- 자체 상품 -->
      <div v-else>
        <div class="lp-divider-gray1 padding-top-24"></div>
        <div class="flex-between padding-top-24">
          <div class="h7">총 결제금액</div>
          <div class="h4">{{ discountedPrice | currency }}</div>
        </div>
      </div>
    </div>

    <div class="mobile">
      <div v-if="product.enable_option">
        <!-- 필수옵션 -->
        <div v-for="(selector, idx) in optionArr1" :key="`option1-${idx}`">
          <div v-if="!selector.disabled">
            <div class="padding-top-24 body4-bold padding-bottom-4">{{ selector.name }}</div>
            <!-- 옵션 정보 -->
            <div v-for="(option, o_idx) in selector.options" :key="`op1-${o_idx}`">
              <div class="option-st unselect"
                   :class="{'disabled': selector.disabled, 'selected': option.selected}"
                   @click="selectOption1(option, selector)"
                   v-if="!option.disabled">
                <div class="body4-medium sub" v-html="optionName(option.name)"></div>
                <div class="margin-top-4" v-if="option.price>0 && idx>0">
                  <span class="body4-bold main">{{ option.price | currencyNum }}</span>
                  <span class="body4 sub">원</span>
                  <span class="body4 sub3" v-if="option.is_subscription">/월</span>
                </div>
                <div class="body6 sub3 margin-top-4">{{ option.desc }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- 추가옵션 -->
        <div v-if="visibleOption2">
          <div class="lp-divider-gray3 margin-top-24"></div>
          <div v-for="(selector, idx) in optionArr2" :key="`option2-${idx}`">
            <div class="padding-top-24 body4-bold padding-bottom-4">{{ selector.name }}</div>
            <!-- 옵션 정보 -->
            <div v-for="(option, o_idx) in selector.options" :key="`op2-${o_idx}`">
              <div class="option-st unselect"
                   :class="{'selected': option.selected}"
                   @click="selectOption2(option)">
                <div class="flex-between">
                  <div class="body4-medium sub" v-html="optionName(option.name)"></div>
                  <!--<div v-if="option.price>0">
                    <span class="body4-bold main">+{{ option.price | currencyNum }}</span>
                    <span class="body4 sub">원</span>
                    <span class="body4 sub3" v-if="option.is_subscription">/월</span>
                  </div>-->
                </div>
                <div class="body5 sub3 margin-top-4">{{ option.desc }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="lp-divider-gray1 padding-top-32"></div>
        <div class="flex-between padding-top-16">
          <div class="body5-medium">
            <span class="sub2">상품금액</span>
          </div>
          <div>
            <span class="body3-bold main">
              {{ order_product.required_option_price | currencyNum }}
            </span>
            <span class="body5 sub">원</span>
          </div>
        </div>
        <div class="flex-between padding-top-4" v-if="order_product.add_option_price">
          <div class="body5-medium">
            <span class="sub2">추가옵션</span>
          </div>
          <div>
            <span class="body3-bold main">
              {{ order_product.add_option_price | currencyNum }}
            </span>
            <span class="body5 sub">원</span>
          </div>
        </div>
        <div class="flex-between margin-top-4" v-if="!isRequiredOptionDesign">
          <div class="body5-medium">
            <span class="sub2">{{ product.service_use_price.name }}</span>
            <span class="primary">(월결제)</span>
            <tool-tip style="margin-left:4px"
                      :iconSize="18"
                      icon="u_question-circle"
                      text="사업 운영에 필요한 관리자 사이트 등이 포함된 운영 솔루션이 제공됩니다."/>
          </div>
          <div>
            <span class="body3-bold main">+{{ product.service_use_price.price | currencyNum }}</span>
            <span class="body5 sub">원</span>
            <span class="body5 sub3">/월</span>
          </div>
        </div>
        <div class="lp-divider-gray2 padding-top-16"></div>

        <div class="flex-between padding-top-12">
          <div class="subtitle6 main">총 결제금액</div>
          <div class="body0-bold main">{{ order_product.total_price | currencyNum }}원</div>
        </div>
        <div class="body6 sub3" style="margin-top:10px" v-if="isRequiredOptionDesignSkinAndModify">
          *위 금액에는 견적서를 통해 발송될 수정 개발 서비스에 대한 요금이포함되지 않았습니다.
        </div>
        <div class="padding-top-16">
          <button class="button is-gray2 body5 flex-center" style="width:100%;height:40px">
            <img src="/static/icon/fi_alert-circle.svg"
                 style="width:16px;height:16px" alt="" class="unselect svg-sub3">
            <span class="margin-left-4 sub3">결제는 데스크톱에서 진행해주세요.</span>
            </button>
        </div>
      </div>
      <div v-else-if="product.category2===42 || product.category2===45">
        <div class="flex-between padding-top-24" v-if="product.price.price_type===0">
          <div class="h7">총 결제금액</div>
          <div class="h4">{{ discountedPrice | currency }}</div>
        </div>
        <div class="padding-top-24" v-else>
          <div class="h7">관리자에게 문의</div>
        </div>
        <div class="padding-top-16">
          <button class="button is-primary body4-medium" style="width:100%" @click="clickInquiry">1:1 문의 하기</button>
        </div>
      </div>
      <!-- 자체 상품 -->
      <div v-else>
        <div class="flex-between">
          <div class="subtitle6">총 결제금액</div>
          <div class="body0-bold">{{ discountedPrice | currency }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import OrderMixin from "../../mixins/OrderMixin";
  import ToolTip from "./ToolTip";

  export default {
    name: "ProductOptionSelector",
    components: {ToolTip},
    mixins: [
      OrderMixin
    ],
    props: {
      product: {
        type: Object
      }
    },
    created() {
      this.init();
    },
    data() {
      return {
        isServiceUsePrice: false,
        // 비교할 상품 옵션
        selectorStocks: [],
        // 필수옵션
        optionArr1: [],
        // 추가옵션
        optionArr2: [],
        order_product: undefined,
        // 필수값 선택값 체크용
        requiredOptionsValue: [],
        visibleOption2: true,
        // 정기 결제 금액
        subscribe_price: 0,
        design_skin: '디자인 대로 바로 개설',
        design_skin2: '디자인 스킨에 부분 수정 개발하여 개설',
        design_skin3: '디자인 스킨 파일만 구매'
      }
    },
    computed: {
      // 필수 옵션 디자인 스킨 + 부분 수정 개발 요청 (견적서 발송 예정) 있는지 여부
      isRequiredOptionDesignSkinAndModify() {
        let list = [
          this.design_skin2 + ' x 월 정기구독',
          this.design_skin2 + ' x 건 결제',
          '월 정기구독 x ' + this.design_skin2,
          '건 결제 x ' + this.design_skin2
        ]
        let opo_item = this.order_product.order_product_option.filter(item=>item.option_type===0)
        return opo_item.some(item=>{
          let stock = this.findItem(this.selectorStocks, 'id', item.option_id);
          return list.indexOf(stock.name) > -1
        })
      },
      // 필수 옵션 디자인 파일만 구매 있는지 여부
      isRequiredOptionDesign() {
        let list = [
          this.design_skin3 + ' x 플랜없음',
          '플랜없음 x ' + this.design_skin3
        ]
        let opo_item = this.order_product.order_product_option.filter(item=>item.option_type===0)
        return opo_item.some(item=>{
          let stock = this.findItem(this.selectorStocks, 'id', item.option_id);
          return list.indexOf(stock.name) > -1
        })
      },
      // 할인 여부
      isDiscount() {
        if (this.product.price.is_discount && this.product.price.discount_start !== null && this.product.price.discount_end !== null) {
          let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
          return !(currentDate < this.product.price.discount_start || currentDate > this.product.price.discount_end);
        } else if (this.product.price.discount_start !== null && this.product.price.discount_end === null) {
          let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
          return currentDate >= this.product.price.discount_start;
        } else if (this.product.price.discount_start === null && this.product.price.discount_end !== null) {
          let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
          return currentDate <= this.product.price.discount_end;
        } else {
          return true;
        }
      },
      // 상품 가격
      discountedPrice() {
        let total = 0;
        if (this.isDiscount) {
          if (this.product.price.discount_rate !== 0 && this.product.price.discount_type === 0) {
            total = this.product.price.price * (1 - (this.product.price.discount_rate / 100))
          } else if (this.product.price.discount_price !== 0 && this.product.price.discount_type === 1) {
            total = this.product.price.price - this.product.price.discount_price;
          }
        } else total = this.product.price.price;
        return total;
      },
    },
    methods: {
      init() {
        this.order_product = {
          product_id: this.product.id,
          quantity: 1,
          discount_price: 0,
          product_price: 0,
          total_price: 0,
          product: this.cloneItem(this.product),
          order_product_option: [],
          required_option_price: 0,
          add_option_price: 0
        }
        this.initOptions();
        this.getData();
      },
      initOptions() {
        // 옵션 초기화
        let selectors = this.cloneItem(this.product.selectors)
        this.optionArr1 = selectors.filter(item => {
          item.disabled = true;
          item.options.forEach(op_item=>{
            if(op_item.name==='플랜없음') {
              op_item.disabled = true;
            }
          })
          return item.option_type===0;
        })
        if(this.optionArr1.length>0) {
          this.optionArr1[0].disabled = false;

          // 디자인 스킨일 경우 기본 선택 표시
          let options = this.optionArr1[0].options;
          if(options.length>0 && options[0].name===this.design_skin) {
            options[0].selected = true;
            this.requiredOptionsValue.push({
              selector_id: this.optionArr1[0].id,
              option_id: options[0].id
            });

            if(this.optionArr1.length>1) this.optionArr1[1].disabled = false;
          }
        }
        this.optionArr2 = selectors.filter(item => {
          return item.option_type===1;
        })
      },
      getData() {
        let url = `public/product/${this.product.id}/selector_stocks`
        // let url = `http://localhost:8001/public/product/${this.product.id}/selector_stocks`
        this.$axios.post(url).then(res=>{
          this.selectorStocks = res.data.stocks;
        })
      },
      optionName(name) {
        let word = '(견적서 발송 예정)';
        if (name.indexOf(word) > -1) {
          return `<span>${name.replace(word, '')} <span style="color:#FF3B3B">${word}</span>`;
        } else {
          return name;
        }
      },
      selectedOptionName(selectorStocks, option_id) {
        if(!selectorStocks) return;
        let stock = this.findItem(selectorStocks, 'id', option_id);
        if (stock) return stock.price > 0 ? `${stock.name}: +${stock.price}원` : `${stock.name}: ${stock.price}원`;
      },
      // 추가옵션 선택
      selectOption2(option) {
        if(!option.selected) {
          option.selected = true;
          this.selectorStocks.forEach(stock=>{
            if(stock.options[0]===option.id) {
              let option = this.findItem(this.order_product.order_product_option, 'option_id', stock.id);
              if(!option) {
                this.order_product.order_product_option.push({option_id: stock.id, quantity: 1, option_type: stock.option_type});
              }
            }
          })
        } else {
          option.selected = false;
          this.selectorStocks.forEach(stock=>{
            if(stock.options[0]===option.id) {
              let option_index = this.order_product.order_product_option.findIndex(opo=> { return opo.option_id === stock.id });
              if(option_index>-1) {
                this.order_product.order_product_option.splice(option_index, 1);
              }
            }
          })
        }
        this.calcTotalPrice();
      },
      isDesignFile(option) {
        /* 디자인 파일(필수옵션)이 선택될 경우 디자인 파일(추가옵션) 삭제*/
        this.visibleOption2 = true;
        if (option.name === this.design_skin3) {
          this.visibleOption2 = false;
          let opoIndex = this.order_product.order_product_option.findIndex(opo => opo.option_type === 1)
          if (opoIndex > -1) this.order_product.order_product_option.remove(opoIndex);

          this.optionArr2.forEach(item=>{
            item.options.forEach(op=>{
              op.selected = false;
            })
          })

          /*let opoIndex = this.order_product.order_product_option.findIndex(opo => opo.option_type === 1)

          if (opoIndex > -1) {
            for (let stock of this.selectorStocks) {
              if (stock.id === this.order_product.order_product_option[opoIndex].option_id && stock.name === '디자인 파일') {
                this.order_product.order_product_option.remove(opoIndex);
              }
            }
          }*/
        }
      },
      // 필수옵션 선택
      selectOption1(option, selector) {
        /*
        * 예외처리
        * 디자인 스킨일 경우 플랜선택 가능
        * 디자인 스킨 제외 단일 선택 가능
        * 디자인 파일(필수옵션) 선택시 디자인 파일(추가옵션) 비노출
        * */
        this.isDesignFile(option);

        let option_id = option.id;
        if(!option_id) return;

        // 첫번째 옵션 선택할 경우 2번째부터는 선택해제
        if(this.optionArr1[0].id===selector.id) {
          this.subscribe_price = 0;
          this.requiredOptionsValue = [];
          // this.order_product.order_product_option = [];
          this.initOrderProductOption();
          this.optionArr1.slice(1, this.optionArr1.length).forEach(item=>{
            item.options.forEach(op=>{
              op.selected = false;
            })
          })
        }
        // 옵션 선택시 false로 초기화 후 옵션 선택
        selector.options.forEach(op=>{
          op.selected = false;
        })
        option.selected = true;

        // 필수 선택값 조합 체크 후 requiredOptionsValue에 추가
        let selector_item = this.findItem(this.requiredOptionsValue, 'selector_id', selector.id);
        let selectorIndex = this.optionArr1.findIndex(item => item.id===selector.id)

        // 첫번째 선택한 옵션이 디자인 스킨일 경우에만 2번째 선택 옵션 목록 노출
        if(selectorIndex+1<this.optionArr1.length) {
          this.optionArr1[selectorIndex+1].disabled = true;
          if(selectorIndex+1<this.optionArr1.length && (option.name===this.design_skin || option.name===this.design_skin2)) {
            this.optionArr1[selectorIndex+1].disabled = false;
          }
        }
        // 디자인 스킨일 경우 또는 디자인 스킨 선택한 경우
        if((option.name===this.design_skin || option.name===this.design_skin2) || this.requiredOptionsValue.length>0) {
          if (!selector_item) {
            this.requiredOptionsValue.push({
              selector_id: selector.id,
              option_id: option_id
            });
          } else {
            selector_item.option_id = option_id
          }
          // 마지막 필수 옵션 선택한 경우
          if(selectorIndex+1===this.optionArr1.length) {
            this.calcSubscribePrice(option);
            let selectedOptions = [];
            this.requiredOptionsValue.forEach(item => {
              selectedOptions.push(item.option_id);
            })
            selectedOptions.sort(function(a,b) {
              return a-b;
            })
            // 주문 상품 추가 (order_product_option)
            for(let stock of this.selectorStocks) {
              stock.options.sort(function(a,b) {
                return a-b;
              })
              // 재고 배열에서 선택된 옵션 조합들을 찾으면 재고 id를 order_product_option에 추가
              if(JSON.stringify(stock.options)===JSON.stringify(selectedOptions)) {
                this.initOrderProductOption();
                this.order_product.order_product_option.push({option_id: stock.id, quantity: 1, option_type: stock.option_type});
                break;
              }
            }
            // this.requiredOptionsValue = [];
          } else {
            this.requiredOptionsValue = this.requiredOptionsValue.slice(0, selectorIndex+1)
          }
        }
        // 디자인 스킨이 아닐 경우
        else {
          this.selectorStocks.forEach(stock=>{
            // selectorStocks에서 선택한 옵션이 포함되면서 플랜없음이라는 문구가 들어 있을 경우
            if(stock.options.indexOf(option.id) > -1 && stock.name.indexOf('플랜없음') > -1) {
              this.initOrderProductOption();
              this.order_product.order_product_option.push({option_id: stock.id, quantity: 1, option_type: stock.option_type});
            }
          })
        }
        this.calcTotalPrice();
      },
      calcTotalPrice() {
        this.isServiceUsePrice = false;
        let total = 0;
        let requiredOptionPrice = 0;
        let addOptionPrice = 0;
        // 상품 옵션일 경우
        if(this.product.enable_option) {
          // required_option_price = 필수옵션
          // add_option_price = 추가옵션
          // total_price = 필수옵션 + 추가옵션
          this.order_product.order_product_option.forEach(item=> {
            let op = this.findItem(this.selectorStocks, 'id', item.option_id);
            if(op!==undefined) {
              if(item.option_type===0) {
                requiredOptionPrice += op.price * parseInt(item.quantity)
              } else if(item.option_type===1) {
                addOptionPrice += op.price * parseInt(item.quantity)
              }
              total += op.price * parseInt(item.quantity)
            }
          })
        }
        // 자체 상품일 경우
        else {
          total = this.discountedPrice * this.order_product.quantity;
        }
        this.order_product.product_price = total;
        this.order_product.required_option_price = requiredOptionPrice;
        this.order_product.add_option_price = addOptionPrice;
        // 서비스 운영 이용료 있을 경우 금액 추가
        if(this.product.service_use_price && !this.isRequiredOptionDesign) {
          this.order_product.total_price = total + this.product.service_use_price.price;
          this.isServiceUsePrice = true;
        } else {
          this.order_product.total_price = total;
        }
      },
      // 정기결제 금액
      calcSubscribePrice(option) {
        if(option.is_subscription) this.subscribe_price = option.price;
        else this.subscribe_price = 0;
        this.subscribe_price += this.product.service_use_price.price;
      },
      initOrderProductOption() {
        let opoIndex = this.order_product.order_product_option.findIndex(opo=> opo.option_type===0)
        if(opoIndex>-1) this.order_product.order_product_option.remove(opoIndex);
      },
      saveData() {
        // if(this.user.user_id===0)
        let item = this.findItem(this.order_product.order_product_option, 'option_type', 0);
        if(!item) {
          this.toast('필수 옵션을 선택하세요.');
        } else {
          let params = {
            type: 'theme',
            order_product: this.cloneItem(this.order_product),
            subscribe_price: this.subscribe_price,
            isServiceUsePrice: this.isServiceUsePrice
          };
          this.$store.commit('setBasket', params);
          this.routeGa('', this.product.title, '결제하기');
          this.routerPush('/order', true);
        }
      },
      clickInquiry() {
        this.routeGa('', this.product.title, '1:1문의하기');
        if(this.product.category2===42) this.routerPush('/inquiry_reg?type_id=7', true);
        else if(this.product.category2===45) this.routerPush('/inquiry_reg?type_id=10', true);
        else this.routerPush('/inquiry_reg', true);
      }
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .option-st
    border 1px solid $gray1
    border-radius 8px
  .pc
    .option-st
      padding 16px 24px
      margin-top 16px
    .option-st:hover
      border 1px solid $sub3
  .mobile
    .option-st
      padding 12px 16px
      margin-top 8px

  .disabled
    background-color #eee

  .selected
    border 2px solid $primary !important

  .plan-select-wrapper
    display grid
    grid-gap 12px
    grid-template-columns repeat(2, 1fr)
</style>
